import { render, staticRenderFns } from "./punktewaehler.vue?vue&type=template&id=403f2483&scoped=true&"
import script from "./punktewaehler.vue?vue&type=script&lang=js&"
export * from "./punktewaehler.vue?vue&type=script&lang=js&"
import style0 from "./punktewaehler.vue?vue&type=style&index=0&id=403f2483&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "403f2483",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VContainer})
